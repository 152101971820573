.logfoot {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #282828;
  border-top: 2px solid #a4cc4c;
  text-align: center;
  color: white;
}

.cookiePolicy {
  color: 'white';
}
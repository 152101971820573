#app {
  @import "./Colors.scss";
  // This is element where react is rendered
  .ReactApp {
    //padding: 1em;
  }

  .ReactAppLogin {
    box-shadow: 0 0 4px rgba(0,0,0,0.3);
    text-align: left;
    width: 400px;
    height: auto;
    border: 2px solid $lightGreyBorder;
    border-radius: 2px;
    position: relative;
    z-index: 1;
    background: $backgroundGreyish;
    max-width: 400px;
  }

  .pointer {
    cursor: pointer;;
  }
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  background: url('./images/body-bg.gif');
  counter-reset: rowCount;
}

.logfoot {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #282828;
  border-top: 2px solid #a4cc4c;
  text-align: center;
  color: white;
}

@import "../../Colors";
%filter-box {
  background: $white;
  border: 0.2em solid $filterGreyBorder;
  border-top: 0.3em solid $themeColor;
  margin: 0 0.5em 0 0;
  height: 100%;
}

%row-flex {
  padding: 0 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

%filter-header {
  border-bottom: 0.2em solid $filterGreyBorder;
  height: 40px;
}

%filter-body {
  height: calc(100% - 40px);
  overflow: auto;
}

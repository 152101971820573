.date-picker {
  position: relative;
  display: flex;
}

.select-filter {
  width: 100%;
}

.clear-date {
  display: flex;
  align-self: center;
}

.date-input {
  width: 95%;
}

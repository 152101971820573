.radar-chart-datapoints {
  max-height: 400px;
  min-width: 250px;
  overflow: scroll;
}

.radar-chart-filter-div {
  padding-left: 5px;
  padding-right: 5px;
}

@import "../../Colors.scss";
.TableComponent {
  &__table {
    td, th {
      &:first-child {
        text-align: center;
        vertical-align: middle;
      }
    }
    th.expand-cell-header {
      width: 55px;
    }
  }
}
.table-cell {
  margin-left: 4px;
  margin-top: 7px;
}

.table-column-header {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.table-header-right {
  margin: 3px;
}
.table {
  &-view {
    border-left: 1px solid $themeColor;
    border-right: 1px solid $themeColor;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    table {
      background-color: $white;
      margin-bottom: 0;
      thead > tr > th {
        /*border: none;*/
        border-color: $themeColor;
        vertical-align: top;
      }
      tbody > tr >td {
        border-color: $themeColor;
      }
    }
  }

  &-toolbar {
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    height: 100%;
    background: $toolbar-bg;
    border-top: 1px solid $themeColor;
    border-left: 1px solid $themeColor;
    border-right: 1px solid $themeColor;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    justify-content: space-between;

    &-name {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      width: 60%;
      margin: 0 1em;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-right: 1em;
      .btn {
        margin-left: 0.5em;
      }
    }
  }
}

.table-module-area {
  margin-bottom: 15em;
}

.history-table {
  margin-top: 10px;
}

.default-column-width {
  width: 200px;
  word-break: break-all;
}

.name-column-width {
  width: 300px;
}

.date-column-width {
  width: 180px;
}

.pagination {
  margin: 0px;
}

.question-show-hide-component {
  max-height: 400px;
  overflow: auto;
}

.header-cell {
  vertical-align: top;
}

.expand-cell-header {
  width: 55px;
}

.excel-icon {
  width: 20px;
  height: 20px;
}

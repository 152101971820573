.active-div {
  margin: 10px 0px;
}

.lh-list {
  height: 98vh;
  overflow: scroll;
}

.lh-list .form-check-label {
  font-size: 12px;
}

.lh-label {
  margin-left: 5px;
}
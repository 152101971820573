.ModuleSelectionContainer {
  @import "../../Colors.scss";
  margin: 0.5em;
  .btn-group {
    margin-left: 3px;
  }
  &__btn {
    &:focus, &:active, &:hover {
      outline: none;
      border-color: unset;
    }
    span {
      margin-left: 0.5em;
    }
    &--active {
      background-image: unset;
      background-color: $activeButton !important;
    }
  }
  .progress-bar {
    background-color: #5bc0de;
  }

  .table-active {
    background-color: #32b8c4;
  }

  .filters-indicator {
    font-size: 7px;
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

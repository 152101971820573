.invalid-date {
  color: red;
}

.ongoing-date {
  color: yellowgreen;
}

.done-date {
  color: #17cc41;
}

.edit-status {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 10px;

  &:hover {
    color: turquoise;
    cursor: pointer;
  }
}

.edit-form-group {
  display: inline-block;
  width: 95%;
}

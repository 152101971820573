.signature-canvas {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.signature-buttons {
  display: flex;
  justify-content: space-between;
}

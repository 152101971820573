.subquestions {
  padding-left: 20px;
  border-left: 5px solid #32b8c4;
  width: 100%;
  margin-top: 10px;
}

.subquestion-indicator {
  margin: 5px;
  float: right;
}

.subquestions > :not(.radio):not(.checkbox) > label {
  padding-left: 0;
  margin-bottom: 5px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .radio input[type=radio],
  .checkbox input[type=checkbox] {
    margin-top: 3px;
  }
  .subquestions {
    padding-left: 10px;
    border-left: 3px solid #32b8c4;
  }
}

.option-media {
  max-width: 100%;
  max-height: 300px;
}

.styled-boolean-yes {
  background-color: green !important;
  color: #ffffff;
}

.styled-boolean-no {
  background-color: red !important;
  color: #ffffff;
}
.document-file {
  margin: 1rem !important;
  width: 200px;

  .figure-caption {
    text-align: center;
  }
  .document-file-action {
    margin-top: -40px;
    margin-bottom: 10px;
    display: flex !important;
    justify-content: space-between !important;
  }
}

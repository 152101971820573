.loading-inner {
  width: 100%;
  height: 150px;
  background: url('../../images/wait.gif') 50% 50% no-repeat;
}

.loading-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(50, 184, 196, 0.3);
  z-index: 1000;
}
.FiltersMenuContainer {
  @import "../../Colors";
  margin: 0.5em;
  &__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-color: $lightGreyBorder;
    background-color: $backgroundGreyish;
    padding: 0.5em;
  }
}

.Brand {
  @import "../../Colors.scss";
  &__upgradeButton {
    margin-right: 10px;
    margin-top: 5px;
    background: $redButton;
    color: $white;
    font-weight: bold;
    overflow: hidden;
    float: right;
  }
}

.lookup-options-div {
  max-height: 300px;
  overflow-y: scroll;
  padding-left: 5px;
}

ul.validation-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  width: 96%;
  max-height: 300px;
  overflow: auto;
  border: 1px solid grey;
}

li.list-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid grey;
}
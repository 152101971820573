.payment-table {
  background-color: white;
}

.expiry-slash {
  display: flex;
  justify-content: center;
  span {
    align-self: center;
  }
}

.report-menu-modal {
  width: 80%;
  max-width: none!important;
}

.report-action-icon {
  margin-right: 5px;
  float: right;
  line-height: inherit;
}

.report-name {
  display: inline-block;
  width: 100%;
}

.report-select-multiple select[multiple] {
  height: 220px;
}

.move-left-btn {
  margin-left: auto;
}

.GalleryItemComponent {
  @import '../../Colors';

  margin: 10px;
  width: 200px;
  height: 200px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &__img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: block;
  }

  &-overlay {
    display: none;
    :hover > & {
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      opacity: 0.75;
      height: 60%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black;
      padding: 0.3em;
    }

    &__description {
      color: $white;
      padding-left: 0.5em;
    }

    &-actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      &__action {
        font-size: 1em;
        color: $thumbnailGlyphicon;
        padding: 0.2em;
        &:hover {
          color: lighten($thumbnailGlyphicon, 10%);
        }
      }
    }
  }
}

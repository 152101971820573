.doc-creation-date {
  text-align: end;
}

.doc-item {
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
  &.active {
    background-color: #f0f0f0;
  }
}

.LoginComponent {
  .form-header {
    width: 100%;
    border-bottom: 1px solid #D4D4D4;
    padding: 20px;
  }

  form {
    padding: 20px;
    #errorId {
      display: none;
    }
    .forgot-change {
      text-align: center;
    }

    .googleSignInDiv {
      &__label {
        font-weight: bold;
        font-size: 100%;
        color: #000;
        margin-right: 1em;
      }
    }
  }

  .logtext {
    margin-top: 1em;
    font-weight: bold;
  }

  .no-ie {
    padding: 15px;
  }

  .login-or {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: 15px 0;
  }

  .login-or::before, .login-or::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px;
  }

  .login-or::before {
    margin-right: 8px;
  }

  .login-or::after {
    margin-left: 8px;
  }
}

.login-wrapper {
  width: 400px;
  margin: 0 auto;
}

.sign-in-header {
  margin-top: 5px;
  text-align: center;
}

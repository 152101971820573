.ul.validation-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  width: 96%;
  max-height: 300px;
  overflow: auto;
  border: 1px solid grey;
}

.regexperror {
  color: #a94442;
}

.validation-list-item {
  padding: 5px 10px 5px;
  border-bottom: 1px solid #ced4da;
  height: 50px;
  display: flex;
  &:hover {
    background-color: rgba(0, 116, 217, 0.1);
  }
  .validation-item-label {
    width: 100%;
    height: 100%;
    padding: 10px 0px 10px 5px;
  }
  .validation-item-right-btn {
    height: 100%;
    width: 15px;
    padding-top: 5px;
  }
}

.document-activity {
  box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
  background-color: white;
  border: 1px solid #eee;
  padding: 15px;
}

.activity-document-file-name {
  font-weight: 300;
  font-style: italic;
}

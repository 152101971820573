.ScheduleDayInfoModal {
  @import "../../Colors.scss";
  &__table {
    &__link {
      color: $themeColor;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: lighten($themeColor, 10%);
      }
    }
    td {
      vertical-align: baseline;
    }
  }
}

.note {
  width: 96%;
  padding: 5px;
  text-align: left;
  height: auto;
  font-size: 13px;
}
.note fieldset {
  padding: 15px 15px 0px;
  margin-bottom: 15px;
  border-radius: 4px;
  -khtml-border-radius: 4px;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  -khtml-border-radius: 0 0 6px rgba(0,0,0,0.2);
}
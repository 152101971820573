.payments-date-input {
  width: 150px;
}

.payments-integer-input {
  width: 150px;
}

.payment-form {
  background-color: white;
  padding: 20px;
}

.body-container {
  margin: 10px 5px;
  height: calc(100% - 110px);
  .doc-container {
    margin: 10px 0px;
    height: calc(100% - 30px);
  }
  .doc-sub-view {
    box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
    background-color: white;
    border: 1px solid #eee;
    padding: 15px;

    .btn, .dropdown-item {
      span {
        margin-left: 0.5em;
      }
    }
  }
}

.docs-left-bar {
  width: 350px;
  height: 100%;
  overflow: scroll;
}

.docs-main-content {
  margin: 0 auto;
  padding: 0px 15px;
  height: 100%;
  overflow: scroll;
}

.option-box {
  border: 1px solid #ced4da;
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  background-color: white;
  p {
   margin-bottom: 0px; 
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  span {
    float: left;
  }
  &-default {
    width: 100%;
    color: black;
    &:hover {
      background-color: rgba(50,184,196, 0.8);
      color: white;
    }
    &-selected {
      background-color: #32b8c4;
      color: white;
    }
  }
}

.colored-option {
  padding-left: 0px !important;
}

.option-label {
  p {
    margin-bottom: 0px;
  }
}


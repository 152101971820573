.tree-workspace {
  background-color: white;
  border-top: 1px solid;
  padding-top: 10px;
}

.LHWorkSpace-tree {
  width: 100%;
}

.lh-node-type {
  background-color: #32b8c4;
  border: 1px solid;
  border-radius: 1;
  color: #ffffff;
  text-align: center;
  font-size: 8pt;
  padding: 0 5px;
}

.lh-node-title {
  font-size: small;
  margin-right: 7px;
  margin-left: 3px;
}

.btn-tree-icon {
  padding: 0.25rem 0.25rem;
  cursor: pointer;
  line-height: 1;
  float: right;
}

.deleted-node {
  font-style: italic;
}

.filter-node > .rc-tree-node-content-wrapper {
  color: greenyellow;
}

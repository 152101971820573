.dashboard-container {
  margin-left: 0px;
  margin-right: 0px;

  button {
    margin-left: 3px;
  }
}

.dashboard-name {
  margin: auto 0;
}

.add-chart-span {
  margin-left: 0.5em;
}

.chart-content-spacing {
  margin-right: 10px;
}

.chart-x-axis {
  display: inline-flex;
}

.chart-panel {
  margin-bottom: 10px;
}

.chart-action-btn {
  float: right;
}

.chart-action-btn > button {
  margin-right: 5px;
}

.report-title {
  font-size: 18px;
}

.chart-questions-drop-down {
  max-height: 350px;
  overflow-y: scroll;

  .dropdown-header {
    font-weight: bold;
    color: black;
  }
  label {
    font-size: small;
  }
}

.chart-form-drop-down {
  max-height: 350px;
  overflow-y: scroll;
}

@media (min-width: 576px) {
  .question-select {
    width: calc(100% - 50px);
  }
}

.question-select {
  width: calc(100% - 50px);
}

select[name="legendid"] {
  width: calc(100% - 50px);
}

.single-instance-chart-selector {
  margin-left: 5px;
  float: left;
}

.chart-title-list {
  list-style: none;
}

.chart-box-content {
  height: calc(100% - 35px);
  // overflow: auto;
}

.dashboard-chart-container {
  border: 1px solid #eae7e4;
  background-color: #ffffff;
  padding: 10px;
  height: 100%;
}

@media print {
  .highcharts-scrollbar {
    display: none;
  }
}

.document-view {
  .header {
    align-items: center;
  }
  .btn, .dropdown-item {
    span {
      margin-left: 0.5em;
    }
  }
  .document-content {
    margin-top: 15px;
    .form-label {
      font-weight: 500;
      font-size: 16px;
    }
    .form-control-plaintext {
      font-weight: 300;
    }
  }
}

.single-instance {
  // background-color: #ffffff;
  // border: 1px solid #a6a6a6;
  // border-radius: 4px;
  padding: 0px 7px;
}

.use-colored-background {
  background-color: rgba(50,184,196, 0.4);
}

.top-bar {
  padding-top: 5px;
  margin-bottom: 3px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1001;
  border-bottom: 1px solid rgb(230, 236, 240);
}

.top-bar .close {
  font-size: 1.7em;
}

.poi-name {
  margin-top: 0px;
}

.close-button-holder {
  margin-left: 10px;
}

.right-margin {
  margin-right: 10px;
}

.form-question {
  margin-right: 0px;
  padding-right: 10px;
  padding-left: 0px;
  margin-left: 0px;
}

.img-responsive {
  max-height: 500px;
}

.editing-alert {
  color: red;
}

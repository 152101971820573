@import "../_placeholders.scss";
.LocationsContainer {
  @import "../../../Colors";
  @extend %filter-box;
  // flex-basis: 20%;
  &__header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3px;
    @extend %filter-header;
    &__checkbox {
      label {
        font-size: 1.1em;
        font-weight: 700;
      }
    }
    &__row {
      @extend %row-flex;
    }
    &__unSelectAll {
      cursor: pointer;
      font-weight: bold;
      color: $themeColor;
      &:hover {
        color: lighten($themeColor, 10%);
      }
    }
    &__expandAll {
      label {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
  &__body {
    padding: 0.5em 0.5em;
  }
  &-tree {
    width: 100% !important;
    &__title {
      color: $normalTextColor !important;
      &--hasPOIs {
        font-weight: 700;
      }
    }
    .rc-tree-select {
      &:focus {
        &-selection {
          box-shadow: none !important;
        }
      }
      &-selection {
        border-color: $filterGreyBorder !important;
        box-shadow: none !important;
        &__rendered {
          &:focus {
            outline: none !important;
          }
        }
        &--multiple {
          min-height: 25px;
        }
      }
    }
    &__dropdown {
      box-shadow: none !important;
      border-color: $filterGreyBorder !important;
      //max-height: 15vh !important;
      overflow: auto;
      z-index: unset !important;
      width: 20%;
    }
  }
}

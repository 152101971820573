.import-questions {
  max-height: 500px;
  overflow-y: auto;
}

.error-alert {
  padding: 5px 10px;
  border-radius: 4px;
}

.action-menu-container {
  margin-left: auto;
}

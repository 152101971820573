.add-row {
  margin-left: 20px;
}

table tr {
  counter-increment: row-num;
}

table td.count::before { 
  content: counter(row-num);
  font-weight: bold;
}

.table-question-rows {
  width: 100%;
  max-height: 800px;
  overflow: scroll;
}

.table label.question-label, td span.text-danger {
  display: none;
}

.table label.question-label.Label {
  display: block;
}

.formview .table .form-group {
  padding-left: 5px;
}

.formview .table {
  .count {
    max-width: 30px;
    min-width: 20px;
    vertical-align: middle;
  }
  .counter {
    max-width: 30px;
    min-width: 20px;
    width: 30px;
  }
  .remove {
    min-width: 50px;
    max-width: 120px;
  }
  .form-group {
    margin-bottom: 0px;
  }
  .form-check {
    padding-left: 0px;
  }
}

.formview .table td {
  // min-width: 200px;
  padding: 5px 0px;
}

.formview .table th {
  min-width: 200px;
  padding-left: 5px;
  padding-right: 5px;
}

.custom-width {
  min-width: 0px !important;
}

.field-as-rows-header, .field-as-rows-operation {
  font-weight: bold;
}

.field-as-rows-header span {
  display: inline !important;
}

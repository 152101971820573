.document-list {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;

  .header {
    background-color: lightgray;
    color: black;
    padding: 10px 5px;
    margin: 10px -15px;
  }
}

@media (min-width: 768px) {
  .btn-div {
    padding-top: 24px !important;
  }
}

@media (max-width: 767px) {
  .btn-div {
    padding-top: 10px !important;
  }

  .gps-row {
    display: none !important;
  }

  .gps-row div {
    padding-left: 0;
    padding-right: 0;
  }
}

.gps-row {
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
}

.gps-row div:first-child {
  padding-left: 0;
}

.gps-row div:last-child {
  padding-right: 0;
}

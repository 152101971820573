.table-loading-overlay {
  position: absolute;
  height: 100%;
  top: 100;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 0.5;
}

.table-loading-inner {
  width: 100%;
  height: 50%;
  margin-top: 10%;
  background: url('./images/wait.gif') 50% 50% no-repeat;
}

.feed-component {
  min-height: 200px;
  height: 600px; // calc(100% - 100px)
  overflow-y: scroll;
}

.feed-alert {
  padding: 10px;
  border-radius: 5px;
}

.feed-icon {
  margin-right: 5px;
}

.activation-body {
  padding-top: 20px;
  width: 400px;
  text-align: center;
}

.activation-wrapper {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  text-align: left;
  width: 400px;
  height: auto;
  background-color: #f5f5f5;
  border: 2px solid #D4D4D4;
  border-radius: 2px;
  padding: 20px;
}
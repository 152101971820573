body {
  height: 100%;
}

.lh-app {
  height: 100%;
}

.content {
  height: calc(100% - 50px);
}

.content > div {
  height: calc(100% - 50px);
}

.lh-map {
  height: 400px;
  width: 100%;
}
@import "../_placeholders.scss";
.FormsContainer {
  @import "../../../Colors";
  @extend %filter-box;
  &__header {
    @extend %row-flex;
    @extend %filter-header;
    &__checkbox {
      margin: 0.5em 0;
      label {
        font-size: 1.1em;
        font-weight: 700;
      }
    }
    &__unSelectAll {
      cursor: pointer;
      font-weight: bold;
      color: $themeColor;
      &:hover {
        color: lighten($themeColor, 10%);
      }
    }
  }
  &__forms {
    @extend %filter-body;
    &-form {
      @extend %row-flex;
      justify-content: initial;
      &__checkbox {
        margin: 0.5em 0;
      }
      &__img {
        margin-right: 0.5em;
      }
      &__badge {
        background-color: $blueBadge;
        width: 4em;
        margin-left: auto;
        color: $white;
      }
    }
  }
}

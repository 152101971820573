.thumbnail {
  width: 200px;
  height: 205px;
  margin-bottom: 5px;
  border: 1px solid gray;
  border-radius: 4px;
}

.audio-container {
  margin-bottom: 5px;
  border: 1px solid gray;
  border-radius: 4px;
}

.saved-file {
  float: left;
  height: 240px;
  margin: 5px;
}

.background-diagram {
  margin-bottom: 15px;
}

.thumbnail img {
  max-height: 195px;
}

.action-btn {
  margin-right: 5px;
}

.video {
  width: 190px;
}

.diagram-img {
  max-width: 100%;
  max-height: 500px;
}

.background-diagram-select {
  z-index: 10000;
}

.signature-view {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 10px;
}

.Schedule {
  @import "../../Colors.scss";
  // @import "../../../../node_modules/@fullcalendar/core/main.css";
  // @import "../../../../node_modules/@fullcalendar/daygrid/main.css";
  // @import "../../../../node_modules/@fullcalendar/timegrid/main.css";
  margin: 0.5em 0.5em;
  &-selection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: baseline;

  }
  &-main {
    margin-top: 2em;
    &__form {
      .form-label {
        font-weight: bold;
      }
    }
    &__list {
      max-height: 74vh;
      overflow-y: auto;
      .badge {
        cursor: grab;
      }
    }
    &__calendar {
      height: 90vh;
      .fc-day {
        cursor: pointer;
      }
    }
  }
}

.fc-scrollgrid {
  height: 90vh;
}

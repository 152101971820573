@import "./_placeholders.scss";
.FilterBoxContainer {
  @import "../../Colors";
  @extend %filter-box;
  &__header {
    @extend %row-flex;
    @extend %filter-header;
  }
  &__body {
    padding: 0.5em 0 0 0;
    @extend %filter-body;
  }
}

.UserContainer__header {
  padding: 0px;
}

.DateFilterFormGroup {
  margin-left: 0px;
  margin-right: 0px;
}

.date-filter-form-label {
  padding-right: 0px;
}
$themeColor: #32b8c4;
$lightGreyBorder: #d4d4d4;
$backgroundGreyish: #f5f5f5;
$white: #fff;
$black: #000;
$blueBadge: #5bc0de;
$filterGreyBorder: #f1f1f1;
$redButton: #f00;
$activeButton: #1d5358;
$normalTextColor: #333;

$thumbnailGlyphicon: #227f87;

$toolbar-bg: #e8e8e8;

.date-question {
  max-width: 200px;
  display: inline-block;
}

.date-picker {
  margin-right: 10px;
}

.react-datepicker-popper {
  z-index: 1000;
}
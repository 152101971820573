.address-input-area {
  display:inline-block;
  position:relative;
  width: 100%;
}

.address-search-btn {
  position:absolute;
  top:3px;
  right:15px;
  background: none;
  color: grey;
  border-color: white;
  padding: 3px 5px;
}

.address-search-btn:hover {
  color: grey;
  border-color: white;
  background: none;
}
// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$turquoise: #32b8c4;
$primary: $turquoise;
$link-color: $turquoise;
$link-hover-color: darken($link-color, 15%);

$progress-bar-bg: $primary;

$theme-colors: (
  "primary": $turquoise
);

$card-border-color: $turquoise;
$card-cap-bg: $turquoise;
$card-cap-color: white;
$card-spacer-y: .55rem;
$list-group-active-bg: $turquoise;
$list-group-active-border: $turquoise;

@import "../../node_modules/bootstrap/scss/bootstrap";

a {
  color: $primary;
}

.text-danger {
  font-size: 18px !important;
}

.modal {
  z-index: 10050 !important;
}

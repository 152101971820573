.large-modal {
  min-width: 90%;
}

.btn-empty-static {
  border-color: #5cb85c;
  background: #ffffff;
  color: #5cb85c;

  &:hover {
    background: #ffffff;
    color: #32b8c4;
    border-color: #32b8c4;
  }
}

.part-of-btn {
  white-space: normal;
}

.clear-subform-btn {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  cursor: pointer;
  border: 0;
  line-height: 1;
  font-size: 21px;
  padding: 0;
}

.saved-part-of-forms {
  list-style: none;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
}

.saved-part-of-forms a {
  color: #32b8c4;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #32b8c4;
    text-decoration: underline;
    cursor: pointer;
  }
}

.remove-subform {
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.required {
  color: red;
}

.saved-part-of-forms ul {
  background: #fff;
  padding-inline-start: 0px;
  margin-block-end: 0px;
}

.saved-part-of-forms ul li {
  border-bottom: solid 1px #ddd;
  padding: 10px;
  position: relative;
  transition: all ease 0.3s;
}

.saved-part-of-forms ul li:hover {
  background: rgba(34, 171, 237, 0.1);
}

.saved-part-of-forms ul li:nth-last-child(1) {
  border-bottom: 0 none;
  border-radius: 0px 0px 0px 5px;
}

.saved-part-of-forms ul li:first-child {
  border-radius: 5px 0px 0px 0px;
}

.backdrop-op-1 {
  z-index: 1050;
}

.leaflet-draw-toolbar.leaflet-bar:not(.leaflet-draw-toolbar-top),
.sr-only {
  display: none !important;
}

#global-map {
  height: 500px;
}

.pop-up-view {
  overflow-y: auto;
  height: 170px;
}

.add-poi-list li {
  padding: 3px 0px;
}

.map-peint-custom-control {
  border: 2px #ccc solid;
  background: #ffffff;
  border-radius: 2px;
}

.diagram_map {
  margin-top: 10px;
}
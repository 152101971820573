pre {
  border: 1px solid #dee2e6;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.copy-span {
  margin-left: 10px;
  cursor: pointer;
}

.scim-settings {
  border: 1px solid #dee2e6;
  padding: 5px;
  margin-bottom: 5px; 
}
